<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 mr-auto ml-auto">
        <wizard @on-complete="wizardComplete" @on-change="onChange">
          <template slot="header">
            <h3 class="card-title">Create a new Doctor</h3>
            <h3 class="description">This information will let us know more about the new Employee.</h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">Step One</template>
            <first-step ref="step1" @on-validated="onStepValidated"></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">Step Two</template>
            <second-step ref="step2" @on-validated="onStepValidated"></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">Step Three</template>
            <third-step ref="step3" @on-validated="onStepValidated"></third-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">Step Four</template>
            <fourth-step ref="step4" @on-validated="wizardComplete"></fourth-step>
          </wizard-tab>
        </wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from "./WizardEdit/FirstStep.vue";
import SecondStep from "./WizardEdit/SecondStep.vue";
import ThirdStep from "./WizardEdit/ThirdStep.vue";
import FourthStep from "./WizardEdit/FourthStep.vue";
import swal from "sweetalert2";

import { Wizard, WizardTab } from "src/components/UIComponents";

export default {
  data() {
    return {
      wizardModel: {}
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    Wizard,
    WizardTab
  },

  mounted() {
    this.$store.dispatch("fetchEmployee", this.$route.params.id);
  },

  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };

      if (typeof this.wizardModel.languages == "object" && this.wizardModel.languages !== null)
        this.wizardModel.languages = this.wizardModel.languages.join(",");

      if (validated) this.$store.dispatch("updateEmployee", this.wizardModel);
    },
    onChange(prevIndex, nextIndex) {
      console.log("prev", prevIndex, "next", nextIndex);
    }
  }
};
</script>
